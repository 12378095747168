<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :max-width="dialogWidth"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
  >
    <v-card
      v-touch:swipe.left="cancel"
      class="d-flex flex-column noscroll charcoal "
      tile
    >
      <BaseCloseDialogHeader heading="Ingredients For" @cancel="done" />
      <v-card-text
        :class="textSize + ' paper--text text-center pl-2 pt-0 pb-2'"
      >
        {{ pantryItem.name }}
      </v-card-text>
      <v-card-text
        v-if="updatedPantryItem"
        :class="textSizeSmall + ' paper--text text-center pl-2 pt-0 pb-2'"
      >
        {{ oneDigit(updatedPantryItem.totalWeight) }} g,
        {{ updatedPantryItem.ingredients.length }} ingredients,
        {{ updatedPantryItem.calories }} kCal
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          large
          block
          title="Change Ingredients"
          plain
          color="paper"
          class="charcoalTile"
          @click="enableSearch"
        >
          <span class=" mr-1">
            UPDATE INGREDIENTS
          </span>
          <v-icon color="paper">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
      <v-sheet
        v-if="showEditIngredients"
        rounded
        class="charcoal charcoalTileMenu mx-2 mb-3 py-2"
      >
        <v-card-actions class="py-0">
          <v-textarea
            rows="3"
            filled
            dark
            clearable
            color="progressActive"
            label="Type ingredients (e.g 1 egg, 1 cup of flour)"
            ref="search"
            v-model="search"
            class="pa-0"
            @click:clear="clearSearch"
          />
        </v-card-actions>
        <v-card-actions class="pa-0 mt-n5">
          <BaseActionButton
            text
            v-if="updatedPantryItem"
            dark
            label="Save Changes"
            color="paper"
            @clickedThis="saveChanges()"
          />
          <v-spacer />
          <BaseActionButton
            ripple
            text
            color="progressActive"
            icon="mdi-nutrition"
            @clickedThis="searchIngredients()"
            label="Get Nutrition"
          />
        </v-card-actions>
      </v-sheet>
      <div
        class="silver--text text-left mx-2 mb-2"
        v-for="(ingredient, index) in ingredients"
        :key="index"
      >
        <BasicSimpleNutrition :item="ingredient" />
      </div>
      <v-spacer />
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'
const BasicSimpleNutrition = () =>
  import('@/components/blocks/BasicSimpleNutrition.vue')
export default {
  components: { BasicSimpleNutrition },
  name: 'ViewIngredients',
  mixins: [validationMixin, util],
  data() {
    return {
      loaded: false,
      ingredients: null,
      showEditIngredients: false,
      newPantryItem: null,
      newIngredients: null,
      search: '',
      updatedPantryItem: null
    }
  },
  beforeMount() {
    this.reset()
  },
  mounted() {
    this.loaded = true
    this.setPantryItem()
    this.$nextTick(() => {
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
    this.reset()
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    pantryItem: {
      type: Object
    }
  },
  computed: {
    searchString() {
      let result = ''
      this.ingredients.forEach(el => {
        result = result + el.name + ','
      })
      return result.slice(0, -1)
    },
    ...appConfig
  },
  watch: {
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (!val) this.reset()
      else {
        this.newPantryItem = null
        this.updatedPantryItem = null
        if (this.loaded) this.setPantryItem()
        this.scrollToTop()
      }
    }
  },

  methods: {
    done() {
      this.$emit('done', this.ingredients)
      this.reset()
    },
    clearSearch() {
      this.search = ''
      this.showEditIngredients = false
    },
    enableSearch() {
      this.search = this.searchString
      this.showEditIngredients = true
    },
    setPantryItem() {
      if (this.pantryItem && this.pantryItem.ingredients)
        this.ingredients = JSON.parse(this.pantryItem.ingredients)

      this.loaded = true
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.done()
      }
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    reset() {},
    saveChanges() {
      return axios
        .post(this.baseURL + '/nutrition/recipe/update', {
          id: this.pantryItem.id,
          name: this.pantryItem.name,
          calories: this.updatedPantryItem.calories,
          weight: this.updatedPantryItem.totalWeight,
          updatedItem: this.updatedPantryItem,
          ingredients: this.ingredients
        })
        .then(response => {
          if (response.status == 200) {
            this.$emit('done')
          } else {
            this.noresults = true
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    searchIngredients() {
      return axios
        .post(this.baseURL + '/nutrition/recipe', {
          title: 'New Search',
          ingr: this.search
        })
        .then(response => {
          if (response.status == 200) {
            if (response.data.data.length == 0) this.noresults = true
            else {
              this.updatedPantryItem = response.data.data
              this.newPantryItem = this.updatedPantryItem.totalNutrients
              this.newIngredients = []
              let t = this
              this.updatedPantryItem.ingredients.forEach(item => {
                let ingredient = {}
                ingredient.name = item.text
                ingredient.qty = item.parsed[0].quantity
                ingredient.weight = item.parsed[0].weight
                ingredient.unit = item.parsed[0].measure
                ingredient.food = item.parsed[0].food
                ingredient.foodId = item.parsed[0].foodId
                ingredient.calories =
                  item.parsed[0].nutrients['ENERC_KCAL'].quantity
                ingredient.proteins =
                  item.parsed[0].nutrients['PROCNT'].quantity ?? 0
                ingredient.carbs =
                  item.parsed[0].nutrients['CHOCDF'].quantity ?? 0
                ingredient.fats = item.parsed[0].nutrients['FAT'].quantity ?? 0

                if (item.parsed[0].nutrients['Sugar.alcohol'])
                  ingredient.sugara =
                    item.parsed[0].nutrients['Sugar.alcohol'].quantity
                else ingredient.sugara = 0

                if (item.parsed[0].nutrients['FIBTG'])
                  ingredient.fiber = item.parsed[0].nutrients['FIBTG'].quantity
                else ingredient.fiber = 0

                t.newIngredients.push(ingredient)
              })
              this.ingredients = this.newIngredients
            }
            this.loaded = true
          } else {
            this.noresults = true
            this.loaded = true
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style scoped>
.edamam {
  z-index: 99;
  position: absolute;
  left: 160px;
  top: 6px;
}
</style>
